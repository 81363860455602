var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 fill-height"},[_c('pibot-loading-overlay',{attrs:{"loading":_vm.loading}}),(_vm.error)?_c('v-alert',{staticClass:"ma-3",attrs:{"type":"error","dense":"","dismissible":""},domProps:{"textContent":_vm._s(_vm.error)}}):_vm._e(),_c('pibot-page-heading',{scopedSlots:_vm._u([(_vm.incident && _vm.assessment)?{key:"append",fn:function(){return [_c('pibot-context-menu',{attrs:{"actions":_vm.actions},on:{"click":function (action) { return action.fn(); }}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.assessment ? _vm.assessmentLabels[_vm.assessment.assessment_type] + " Assessment" : "Assessment")+" ")]),(_vm.incident && _vm.assessment)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('pibot-eprs-timeline',{attrs:{"incident":_vm.incident},on:{"change":_vm.setSelectedAssessment}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('pibot-eprs-map',{attrs:{"coordinates":_vm.coordinates}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('pibot-eprs-report-list',{attrs:{"item":_vm.assessment}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.dataTables),function(table){return _c('v-card',{key:table.key,staticClass:"mb-6",attrs:{"outlined":table.key !== 'results',"elevation":table.key === 'results' ? 4 : 0}},[_c('v-toolbar',{attrs:{"dark":"","dense":"","color":table.key !== 'results' ? 'primary darken-1' : _vm.color,"elevation":"0"}},[_c('v-toolbar-title',{domProps:{"textContent":_vm._s(table.title)}})],1),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((table.items),function(ref){
var key = ref.key;
var label = ref.label;
var value = ref.value;
var unit = ref.unit;
return [_c('tr',{key:key + label},[_c('td',{staticClass:"capitalize"},[_vm._v(_vm._s(label))]),(key === 'severity')?_c('td',{staticClass:"capitalize"},[_c('pibot-risk-chip',{attrs:{"risk":value,"color":_vm.color,"small":"","tooltip":true,"tooltip-placement":"bottom"}})],1):_c('td',{staticClass:"capitalize"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!((key === 'delta' || key === 'delta_ratio') && table.items.find(function (e) { return e.key === 'delta_ratio'; }).value > .1)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.valueToHtml(label, value, unit)))])]}}],null,true)},[_c('span',[_vm._v("This value shouldn't be taken litererally, but is indicative of the severity of the situation")])])],1),_c('td',{domProps:{"innerHTML":_vm._s(_vm.unitToHtml(label, value, unit))}})])]})],2)]},proxy:true}],null,true)})],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('pibot-eprs-severity',{attrs:{"assessment":_vm.assessment}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('pibot-eprs-nearby-events',{attrs:{"incident":_vm.incident}})],1)],1)],1)],1),((typeof _vm.aid !== 'undefined'))?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('pibot-pw-confirm',{ref:"pw-confirm",attrs:{"elevation":"0","color":"error darken-1","small":"","hide-activator":"","loading":_vm.loading,"disabled":_vm.loading},on:{"confirmed":function($event){return _vm.deleteItem()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Authenticate")]},proxy:true},{key:"text",fn:function(){return [_c('span',{staticClass:"error--text text--darken-1"},[_vm._v("Are you sure you want to permanently delete this assessment and all relevant data? This cannot be undone.")])]},proxy:true}],null,false,1169389626)})],1)],1)]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }