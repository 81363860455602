<template>
  <div class="w-100 fill-height">
    <!-- Loading overlay -->
    <pibot-loading-overlay :loading="loading" />

    <!-- Alerts -->
    <v-alert
      v-if="error"
      v-text="error"
      type="error"
      dense
      dismissible
      class="ma-3"
    />

    <!-- Page Heading -->
    <pibot-page-heading>
      {{
        assessment
          ? assessmentLabels[assessment.assessment_type] + " Assessment"
          : "Assessment"
      }}
      <template #append v-if="incident && assessment">
        <pibot-context-menu
          :actions="actions"
          @click="(action) => action.fn()"
        />
      </template>
    </pibot-page-heading>

    <!-- Main content -->
    <v-container v-if="incident && assessment">
      <v-row>
        <!-- Assessment revision select -->
        <v-col cols="12">
          <pibot-eprs-timeline
            :incident="incident"
            @change="setSelectedAssessment"
          />
        </v-col>

        <!-- Map -->
        <v-col cols="12">
          <pibot-eprs-map :coordinates="coordinates" />
        </v-col>

        <!-- Reports -->
        <v-col cols="12">
          <pibot-eprs-report-list :item="assessment" />
        </v-col>

        <!-- Data Tables -->
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-card
                v-for="table in dataTables"
                :key="table.key"
                class="mb-6"
                :outlined="table.key !== 'results'"
                :elevation="table.key === 'results' ? 4 : 0"
              >
                <v-toolbar
                  dark
                  dense
                  :color="table.key !== 'results' ? 'primary darken-1' : color"
                  elevation="0"
                >
                  <v-toolbar-title v-text="table.title" />
                </v-toolbar>

                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <template
                          v-for="{ key, label, value, unit } in table.items"
                        >
                          <tr :key="key + label">
                            <td class="capitalize">{{ label }}</td>
                            <td v-if="key === 'severity'" class="capitalize">
                              <pibot-risk-chip
                                :risk="value"
                                :color="color"
                                small
                                :tooltip="true"
                                tooltip-placement="bottom"
                              />
                            </td>
                            <td v-else class="capitalize">
                              <v-tooltip bottom :disabled="!((key === 'delta' || key === 'delta_ratio') && table.items.find(e => e.key === 'delta_ratio').value > .1)">
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">{{ valueToHtml(label, value, unit) }}</span>
                                </template>
                                <span>This value shouldn't be taken litererally, but is indicative of the severity of the situation</span>
                              </v-tooltip>
                            </td>
                            <td v-html="unitToHtml(label, value, unit)" />
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <v-row>
            <!-- Severity Table -->
            <v-col cols="12">
              <pibot-eprs-severity :assessment="assessment" />
            </v-col>

            <!-- Nearby events table -->
            <v-col cols="12">
              <pibot-eprs-nearby-events :incident="incident" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <template v-if="(typeof aid !== 'undefined')">
        <v-row>
          <v-col cols="12">
            <pibot-pw-confirm
              ref="pw-confirm"
              elevation="0"
              color="error darken-1"
              small
              hide-activator
              :loading="loading"
              :disabled="loading"
              @confirmed="deleteItem()"
            >
              <template #title>Authenticate</template>
              <template #text
                ><span class="error--text text--darken-1"
                  >Are you sure you want to permanently delete this assessment
                  and all relevant data? This cannot be undone.</span
                ></template
              >
            </pibot-pw-confirm>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import _ from 'lodash'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'

export default {
  name: 'pibot-eprs-incident',
  mixins: [eprs, mapboxBasicsMixin],
  components: {
    'pibot-loading-overlay': () => import('@/components/overlays/LoadingOverlay'),
    'pibot-page-heading': () => import('@/components/typography/headings/PageHeading'),
    'pibot-eprs-timeline': () => import('./details/Timeline'),
    'pibot-eprs-map': () => import('./details/Mapbox'),
    'pibot-eprs-report-list': () => import('./details/ReportList'),
    'pibot-eprs-severity': () => import('./details/Severity'),
    'pibot-eprs-nearby-events': () => import('./details/NearbyEvents'),
    'pibot-risk-chip': () => import('@/components/chips/RiskChip'),
    'pibot-context-menu': () => import('@/components/buttons/ContextMenu'),
    'pibot-pw-confirm': () => import('@/components/buttons/PasswordConfirm')
  },
  data: () => ({
    coordinates: null,
    nearbyEvents: [],
    assessmentLabels: {
      'dropped object': 'Dropped Object',
      dent: 'Dent-Gouge Combination',
      fatigue: 'Fatigue Life After Dent-Gouge Combination'
    }
  }),
  computed: {
    ...mapState({
      assets: state => state.assets.assets,
      reportMeta: state => state.eprs.reportMeta
    }),
    actions () {
      return [
        {
          label: 'Add follow-up assessment',
          icon: 'addchart',
          fn: () => this.next()
        },
        {
          label: 'Edit and re-evaluate',
          icon: 'edit',
          fn: () => this.editItem()
        },
        {
          divider: true
        },
        {
          label: 'Permantly delete',
          icon: 'delete_forever',
          fn: () => this.pwConfirm()
        }
      ]
    },
    scenario () {
      return this.assessment ? this.assessment.assessment_type : null
    },
    asset () {
      if (!this.assessment) return null

      const d = this.assessment.data.find(({ key }) => key === 'pipeline_name')

      if (!d) return null

      const asset = Object.assign(
        {},
        this.assets.find(
          ({ Name }) => Name.toLowerCase().replace(/\s/g, '-') === d.value
        )
      )

      delete asset.Total_risk // Hard-coded :(

      return asset
    },
    dataTables () {
      return [
        {
          key: 'results',
          title: 'Assessment results',
          items: this.assessment.data
            .filter(({ category, label }) => {
              return ['result'].includes(category) && !!label
            })
            .map(({ key, label, value, unit }) => ({
              key,
              label,
              value,
              unit
            }))
        },
        {
          key: 'asset_properties',
          title: 'Asset properties',
          items: [
            ...(!this.asset
              ? []
              : Object.entries(this.asset).map(([label, value]) => ({
                label,
                value
              }))),
            ...this.assessment.data
              .filter(({ category, label }) => {
                return ['pipeline'].includes(category) && !!label
              })
              .map(({ key, label, value, unit }) => ({
                key,
                label,
                value,
                unit
              }))
          ]
        },
        {
          key: 'user_inputs',
          title: 'Data',
          items: [
            {
              label: 'scenario',
              value: this.scenario
            },
            ...this.assessment.data
              .filter(({ category, label }) => {
                return !['result', 'pipeline'].includes(category) && !!label
              })
              .map(({ key, label, value, unit }) => ({
                key,
                label,
                value,
                unit
              }))
          ]
        }
      ]
    },
    severity () {
      return this.assessment.data.find(({ key }) => key === 'severity').value
    },
    color () {
      switch (this.severity) {
        case 0:
        case 1:
        case 2:
          return 'success darken-2'

        case 3:
          return 'warning'

        case 4:
          return 'warning darken-1'

        case 5:
          return 'error darken-1'

        default:
          return undefined
      }
    }
  },
  async beforeMount () {
    await this.init()
  },
  beforeDestroy () {
    console.warn('for demo purposes, map cache is disabled')
    this.$store.state.mapbox.map = null
    this.$store.state.mapbox.layerObjs = []
    this.$store.state.mapbox.sourceObjs = []
    //   if (this.map) {
    //     this.setIncidentsToggle(false)
    //     this.setLayerVisibility('eprs-incidents', false)
    //   }
  },
  methods: {
    ...mapMutations({
      setIncidentsToggle: 'eprs/SET_MAP_TOGGLE'
    }),
    ...mapActions({
      getIncidentById: 'eprs/socket_fetchIncident',
      fetchAsset: 'assets/socket_fetchAssetData',
      fetchReportMeta: 'eprs/socket_fetchAllReportsMeta',
      delete: 'eprs/socket_deleteAssessment',
      deleteReport: 'eprs/socket_deleteReport'
    }),
    async init () {
      this.unsetMessages()

      const promises = []

      promises.push(this.getIncidentById(this.iid))

      console.error("Report meta breaks on Dennis' PC.")
      // promises.push(this.fetchReportMeta())
      if (!this.assets.length) promises.push(this.fetchAsset())

      Promise.all(promises)
        .then(([incident]) => {
          if (!incident || _.isEmpty(incident)) {
            this.setError('Incident not found.')
            return
          }

          if (!incident?.assessments?.length) {
            this.setError(
              'There are no assessments assigned to this incident.'
            )
            return
          }

          this.incident = incident
          this.coordinates = incident.coordinates

          if (typeof this.aid === 'undefined') {
            this.$router.replace(`${this.$route.fullPath}/${this.incident?.assessments?.[0].assessment_id}`)
          }
          this.assessment = this.incident.assessments.find(
            (a) => a.assessment_id === this.aid
          )
        })
        .catch((error) => {
          this.setError(error.message)
        })
    },
    setSelectedAssessment (aid) {
      this.assessment = this.incident.assessments.find((a) => a.aid === aid)
    },
    valueToHtml (label, value, unit) {
      if (['dent', 'fatigue'].includes(value)) { return this.assessmentLabels[value] }
      if (typeof value === 'string') return value
      // value manipulation for presentation
      if (label === 'Dent depth' && unit === '-') value *= 100
      else if (unit === 'Pa') value /= 1000000
      else if (unit === 'J' && !label.includes('Charpy')) value /= 1000
      else if (unit === 'm' && ['Length', 'Width', 'Height', 'Absolute distance', 'Depth at location'].every(x => x !== label)) value *= 1000
      else if (unit === 'm^2' && label.includes('Charpy specimen')) value *= 1000000
      // round value
      if (value < 0) value = Number(value.toFixed(3))
      else if (value < 10) value = Number(value.toFixed(2))
      else if (value < 100) value = Number(value.toFixed(2))
      else if (value < 1000) value = Number(value.toFixed(1))
      else value = Number(value.toFixed(0))
      // add comma separators
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    unitToHtml (label, value, unit) {
      if (!unit) return unit
      // Mega, mili, kilo or %
      if (label === 'Dent depth' && unit === '-') unit = '%'
      else if (unit === 'Pa') unit = 'MPa'
      else if (unit === 'J' && !label.includes('Charpy')) unit = 'kJ'
      else if (unit === 'm' && ['Length', 'Width', 'Height', 'Absolute distance', 'Depth at location'].every(x => x !== label)) unit = 'mm'
      else if (unit === 'm^2' && label.includes('Charpy specimen')) unit = 'mm^2'
      else if (unit === 'Pa.m^2') unit = 'MPa.mm^2'
      // HTML markup
      if (unit.includes('^')) unit = unit.replace('^', '<sup>') + '</sup>' // This is Not a proper solution
      if (unit.includes('.')) unit = unit.replace('.', '·')
      return unit
    },
    editItem () {
      this.$router.push(
        `/eprs/incident/${this.iid}/assessment/edit/${this.aid}`
      )
    },
    async deleteItem () {
      if (this.aid < 0) return
      const payload = {
        incident_id: this.iid,
        assessment_id: this.aid,
        update_geojson: true
      }
      const report = this.reportMeta.find(
        (d) =>
          parseInt(d.assessment_id) === this.aid && d.incident_id === this.iid
      )
      await Promise.all([
        this.delete(payload),
        this.deleteReport(report.timestamp)
      ]).finally(() => {
        this.$router.push('/eprs/')
      })

      // this.delete(payload)
      //   .finally(() => {
      //     this.$router.push('/eprs/')
      //   })
    },
    next () {
      this.$router.push(
        `/eprs/incident/${this.iid}/assessment/follow-up/${this.aid}`
      )
    },
    pwConfirm () {
      this.$refs['pw-confirm'].dialog = true
    }
  },
  filters: {
    toFixed (value) {
      return typeof value === 'number' ? Number(value.toFixed(3)) : value
    }
  }
}
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
