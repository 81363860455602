import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    incident: null,
    assessment: null
  }),
  computed: {
    ...mapState({
      loading: state => state.eprs.loading,
      isDemo: state => state.eprs.isDemo,
      success: state => state.eprs.success,
      error: state => state.eprs.error
    }),
    iid () {
      return this.$route.params.iid
    },
    aid () {
      return typeof this.$route.params.aid !== 'undefined' ? Number(this.$route.params.aid) : undefined
    },
    isNew () {
      return this.$route.name.includes('new')
    },
    isRevision () {
      return this.$route.name.includes('edit')
    },
    isNext () {
      return this.$route.name.includes('follow-up')
    }
  },
  methods: {
    ...mapMutations({
      unsetMessages: 'eprs/UNSET_MESSAGES',
      setSuccess: 'eprs/SET_SUCCESS',
      setError: 'eprs/SET_ERROR'
    }),
    ...mapActions({
      getIncidentById: 'eprs/socket_fetchIncident'
    }),
    formatDate (timestamp) {
      return moment(timestamp).format('DD MMM YYYY HH:mm:ss UTC Z')
    },
    getColor (severity) {
      switch (severity) {
        case 0:
        case 1:
        case 2:
          return 'success darken-2'

        case 3:
          return 'warning'

        case 4:
          return 'warning darken-1'

        case 5:
          return 'error darken-1'

        default:
          return undefined
      }
    }
  }
}
